<template>

  <div class="col-md-12" v-if="echipa && currentConcurs">
    <span>{{ currentConcurs.concurs.nume }} {{ currentConcurs.concurs.data }}</span>
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 text-gray-800">{{ echipa.echipa.nume }}</h1>

      <router-link :to="{ name:'edit-concurs', params: { id: $route.params.idConcurs} }" class="btn btn-primary btn-icon-split">
          <span class="icon text-white-50">
              <i class="fas fa-arrow-left"></i>
          </span>
          <span class="text">Inapoi</span>
      </router-link>
    </div>



   <div class="card shadow mb-4">
      <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Adauga Greutate</h6>
      </div>
      <div class="card-body">

        <div class="d-flex align-items-center justify-content-between mb-4">
          <input 
            type="number"
            class="form-control greutatePeste"
            pattern="\d+((\.|,)\d+)?"
            placeholder="Greutate pește"
            v-model="greutate"
          >
          
          <span class="btn btn-success btn-icon-split shadow-sm margin-right-btn"  
            :class="{ disabled: adaugaGreutatePending }"
           @click="adaugaGreutate()">
            <span class="icon text-white-50">
              <i class="fas fa-plus"></i>
            </span>
            <span class="text">Adauga</span>
          </span>
  
        </div>

      </div>
  </div>

  <div class="card shadow mb-4">
      <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Istoric Inregistrari</h6>
      </div>
      <div class="card-body">


        <GreutateChart v-if="lineLabels.length" :lineLabels=lineLabels :lineData=lineData />

        <div v-if="inregistrariEchipa && inregistrariEchipa.length">
          <ul class="list-group card shadow mb-4 list_inregistrari">
            <li class="list-group-item" v-for="(inregistrare, index) in inregistrariEchipa" :key="index">
              <div class="d-flex align-items-center justify-content-between mb-0">
                  <div>
                    {{ inregistrare.greutate }} kg <span class="small">inregistrare la {{ showDate(inregistrare.adaugat_la) }}</span>
                  </div>
                  <a class="btn btn-danger btn-icon-split btn-sm shadow-sm" @click="deleteInregistrare(inregistrare.id)">
                    <span class="icon text-white-50">
                        <i class="fas fa-trash"></i>
                    </span>
                  </a>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <p>Nu exista inregistrari pentru echipa.</p>
        </div>
      </div>
  </div>


  <hr />
  <div class="d-sm-flex align-items-center justify-content-between mb-4" v-if="echipa">
      <h2 class="h3 text-gray-800">Optiuni Echipa</h2>
      <div>
        <a class="btn btn-primary btn-icon-split shadow-sm margin-right-btn " @click="echipaEditata = !echipaEditata">
            <span class="icon text-white-50">
              <i class="fas fa-edit"></i>
          </span>
          <span class="text">Editeaza</span>
        </a>
        <a class="btn btn-danger btn-icon-split shadow-sm" @click="deleteEchipa(echipa.id)">
          <span class="icon text-white-50">
              <i class="fas fa-trash"></i>
          </span>
          <span class="text">Sterge</span>
        </a>
      </div>
  </div>

  <div class="card shadow mb-4"  v-if="echipaEditata">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Modifica Echipa</h6>
    </div>
    <div class="card-body">

      <p><input
        type="text"
        class="form-control"
        id="nume_echipa"
        required
        v-model="echipaEditataNume"
        name="nume_echipa"
        placeholder="Nume Echipa"
      /></p>

      <p><input
        type="text"
        class="form-control"
        id="sector_echipa"
        required
        v-model="echipaEditataSector"
        name="sector_echipa"
        placeholder="Sector"
      /></p>

      <p><input
        type="text"
        class="form-control"
        id="stand_echipa"
        required
        v-model="echipaEditataStand"
        name="stand_echipa"
        placeholder="Stand"
      /></p>


      <div class="d-flex align-items-center justify-content-between mb-4">
        <button @click="modificaEchipa" class="btn btn-success">Modifica</button>
        <span @click="echipaEditata = !echipaEditata" class="btn btn-warning delete_echipa">Anuleaza</span>
      </div>
    </div>
  </div>

  

  </div>

</template>
<script>
import PescariDataService from "../services/PescariDataService";
import GreutateChart from './GreutateChart';

export default {
  name: "echipa-single",
  components: { 
    GreutateChart
  },
  data() {
    return {
      echipa: null,
      currentConcurs: null,
      echipaEditata: false,
      inregistrariEchipa: null,
      echipaEditataNume: null,
      echipaEditataSector: null,
      echipaEditataStand: null,
      adaugaGreutatePending: false,
      greutate: null,
      lineLabels:[],
      lineData:[],
    };
  },
  methods: {
     adaugaGreutate(){
      if(this.adaugaGreutatePending) return false;
      this.adaugaGreutatePending = true;

      if(this.greutate <= 0){
        this.greutate = null;
        this.adaugaGreutatePending = false;
        return false;
      }

       PescariDataService.addGreutateEchipa( this.$route.params.idConcurs, this.$route.params.idEchipa, this.greutate)
        .then(() => {
          this.greutate = null;
          this.adaugaGreutatePending = false;
          this.refreshInregistrari();

        })
        .catch(e => {
          this.adaugaGreutatePending = true;
          console.log(e);
        });
    },
    modificaEchipa(){
      var data = {
        nume: this.echipaEditataNume,
        sector: this.echipaEditataSector,
        stand: this.echipaEditataStand,
        id: this.$route.params.idEchipa
      };
      PescariDataService.modificaEchipa( this.$route.params.idConcurs, data)
        .then(() => {
          this.refreshEchipa();
          this.echipa.nume = "";
          this.echipa.sector = "";
          this.echipa.stand = "";
          this.echipaEditata = false;
        })
        .catch(e => {
          console.log(e);
        });
    },
    refreshInregistrari(){
      PescariDataService.getInregistrariEchipa(this.$route.params.idConcurs, this.$route.params.idEchipa).then(
        qSnap => {
          this.inregistrariEchipa = qSnap;
          this.lineData = [];
          this.lineLabels = [];
          for (const result of this.inregistrariEchipa){
            this.lineData.push(result.greutate);
            this.lineLabels.push( this.showDate_hour(result.adaugat_la) );
          }
          this.lineData.reverse();
          this.lineLabels.reverse();

  

        }
      );
    },
    refreshEchipa(){
      PescariDataService.getEchipa(this.$route.params.idConcurs, this.$route.params.idEchipa).then(
        qSnap => {
          this.echipa = qSnap;
          this.echipa.id = this.$route.params.idEchipa;

          this.echipaEditataNume = this.echipa.echipa.nume;
          this.echipaEditataSector = this.echipa.echipa.sector;
          this.echipaEditataStand = this.echipa.echipa.stand
        }
      );
    },
    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      },
    formatDate(date) {
        let dateFormatted = [
          this.padTo2Digits(date.getDate()),
          this.padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/');
        let time = [
          date.getHours(),
          date.getMinutes()
        ].join(':');
        return dateFormatted + " " + time;
    },
    formatDate_hour(date) {
    
        let time = [
          date.getHours(),
          date.getMinutes()
        ].join(':');
        return time;
    },
    showDate_hour(d){
      return this.formatDate_hour( new Date(d.seconds * 1000 + d.nanoseconds/1000000 ) );
    },
    showDate(d){
      return this.formatDate( new Date(d.seconds * 1000 + d.nanoseconds/1000000 ) );
    },
    deleteInregistrare(idInregistrare){
      let result = confirm("Sigur vrei sa stergi inregistrarea?");
      if(result){
        
        PescariDataService.deleteInregistrare(idInregistrare, this.$route.params.idEchipa, this.$route.params.idConcurs )
        .then( () => {
          this.refreshInregistrari();
        })
        .catch(e=>{
          console.error("Error: ", e);
        });
      }
    },
    deleteEchipa(idEchipa){

      let result = confirm("Sigur vrei sa stergi echipa?");
      if(result){
        PescariDataService.deleteEchipa(idEchipa, this.$route.params.idConcurs )
        .then( () => {
            this.$router.push({ name:'edit-concurs', params: { id: this.$route.params.idConcurs} })
        })
        .catch(e=>{
          console.error("Error: ", e);
        });
      }
    },
  }, 
  mounted() {
    this.refreshEchipa();
   
    PescariDataService.getConcurs(this.$route.params.idConcurs).then(
      qSnap => {
        this.currentConcurs = qSnap;
        this.currentConcurs.id = this.$route.params.idConcurs;
      }
    );

    this.refreshInregistrari();
    
  },
};
</script>
<style scoped>
@media screen and (max-width:1000px){
  .col-md-12{
    padding-left:0px;
    padding-right:0px;
  }
}
h1 span{
  font-size:1rem;
}
.greutatePeste{
  margin-right:15px;
}
.list_inregistrari span.small{
  font-size:0.75rem;
}
</style>