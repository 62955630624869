<template>
  <div>
    <canvas id="greutate-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'GreutateChart',
  props:{
    lineLabels:{
      type: Array,
      default: () => []
    },
    lineData:{
      type: Array,
      default: () => []
    },
  },
  watch:{
    lineLabels(newValue){
      this.greutateChartData.data.labels = newValue;
      this.renderChart();
    },
     lineData(newValue){
      this.greutateChartData.data.datasets[0].data = newValue;
      this.renderChart();
    }
  },
  data(){
    return{
      greutateChartData: {
        type: "line",
        data: {
          labels: this.lineLabels,
          datasets: [
            // {
            //   label: "Greutate ",
            //   data: this.lineDataTop,
            //   backgroundColor: "rgba(54,73,93,.5)",
            //   borderColor: "#36495d",
            //   borderWidth: 3
            // },
            {
              label: "Greutate ",
              data: this.lineData,
              backgroundColor: "rgba(71, 183,132,.5)",
              borderColor: "#47b784",
              borderWidth: 3,
            }
          ]
        },
        options: {
          legend:{
            display:false,
          },
          layout:{
            padding:{
              left:0,
              right:0,
              top:25,
              bottom:50
            }
          },
          responsive: true,
          // maintainAspectRatio: false,
          // aspectRatio: 1,
          scales: {
            yAxes: [
              {
                time:{ unit: 'date'},
                ticks: {
                  beginAtZero: true,
                  padding: 10,
                  callback: function(value){
                    return value + " kg";
                  }

                }
              }
            ],
            xAxes: [{
              ticks:{
                maxTicksLimit: 10
              }
            }]
          }
        }
      }
    }
  },
  mounted(){
    this.renderChart()
  },
  methods:{
    renderChart(){
      const ctx = document.getElementById('greutate-chart');
      new Chart(ctx, this.greutateChartData);
    }
  }
}
</script>
